import React from 'react';
import cls from 'classnames';

import { UISref } from '@wix/tpa-router/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { TextButton } from 'wui/TextButton';
import type { TPAComponentProps } from 'wui/types';

import { groupsSearchSearchResultClickSrc111Evid525 } from '@wix/bi-logger-groups-data/v2';

import type { IGroup } from 'store/groups';

import { ListItem } from 'wui/ListItem';
import { ButtonSize } from 'wui/Button';
import { ListItemText } from 'wui/ListItemText';
import { ListItemAction } from 'wui/ListItemAction';

import { GroupInfo } from 'common/components/GroupInfo';
import { GroupMembershipButton } from 'common/components/GroupMembership';

import { GroupListItemImage } from './GroupListItemImage';

import classes from './GroupListItem.scss';

interface IGroupListItemProps extends TPAComponentProps {
  group: IGroup;
  origin: string;
}

export function SuggestedGroupListItem(props: IGroupListItemProps) {
  const { group, className, origin, ...rest } = props;

  const { isMobile } = useEnvironment();

  return (
    <ListItem
      {...rest}
      className={cls(classes.root, className, { [classes.mobile]: isMobile })}
    >
      <UISref
        state="group"
        params={{ slug: group.slug }}
        bi={groupsSearchSearchResultClickSrc111Evid525({
          origin,
          groupId: group.id as string,
          tabName: 'groups',
        })}
      >
        <a tabIndex={-1} className={classes.imageLink}>
          <GroupListItemImage group={group} />
        </a>
      </UISref>

      <ListItemText
        title={
          <UISref
            state="group"
            params={{ slug: group.slug }}
            bi={groupsSearchSearchResultClickSrc111Evid525({
              origin,
              groupId: group.id as string,
              tabName: 'groups',
            })}
          >
            <TextButton as="a" variant="secondary" data-hook="group-name">
              {group.name}
            </TextButton>
          </UISref>
        }
        subtitle={
          <GroupInfo
            data-hook="group-info"
            groupId={group.id as string}
            variant="p2-14"
          />
        }
        titleProps={{
          noWrap: true,
        }}
        subtitleProps={{ noWrap: true }}
      />

      <ListItemAction>
        <GroupMembershipButton
          redirect
          upgrade={!isMobile}
          size={ButtonSize.tiny}
          groupId={group.id as string}
          bi={groupsSearchSearchResultClickSrc111Evid525({
            origin,
            groupId: group.id as string,
            tabName: 'groups',
          })}
        />
      </ListItemAction>
    </ListItem>
  );
}

SuggestedGroupListItem.displayName = 'SuggestedGroupListItem';
