import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import {
  groupsRequestJoinAGroup,
  groupsSelectGroupFromList,
} from '@wix/bi-logger-groups/v2';
import { UISref } from '@wix/tpa-router/react';

import { EImageRatio } from 'settings/constants';

import type { IGroup } from 'store/groups';

import { ListItem } from 'wui/ListItem';
import { ButtonSize } from 'wui/Button';
import { ListItemText } from 'wui/ListItemText';
import { ListItemAction } from 'wui/ListItemAction';
import { ListItemIcon } from 'wui/ListItemIcon';
import { TextButton } from 'wui/TextButton';
import { Box } from 'wui/Box';
import type { TPAComponentProps } from 'wui/types';

import { GroupInfo } from 'common/components/GroupInfo';
import { GroupMembershipButton } from 'common/components/GroupMembership';
import { GroupImage } from 'common/components/GroupImage';

import classes from './GroupListItem.scss';

interface IGroupListItemProps extends TPAComponentProps {
  wired?: boolean;
  group: IGroup;
}

export function SuggestedGroupListItem(props: IGroupListItemProps) {
  const { group, wired, className, ...rest } = props;

  const { isMobile } = useEnvironment();

  return (
    <ListItem
      {...rest}
      disablePadding
      actionOnHover={!isMobile}
      className={cls(className, { [classes.wired]: props.wired })}
    >
      <ListItemIcon className={classes.image}>
        <UISref
          state="group"
          params={{ slug: group.slug }}
          bi={groupsSelectGroupFromList({
            groupId: group.id as string,
            origin: 'new_layout_groups_sidebar_suggested_groups',
          })}
        >
          <Box width={40} height={40} as="a">
            <GroupImage
              width={40}
              height={40}
              resize="cover"
              className={classes.image}
              image={group.coverImage?.image}
              aspectRatio={EImageRatio.Square}
            />
          </Box>
        </UISref>
      </ListItemIcon>

      <ListItemText
        title={
          <Box>
            <UISref
              state="group"
              params={{ slug: group.slug }}
              bi={groupsSelectGroupFromList({
                groupId: group.id as string,
                origin: 'new_layout_groups_sidebar_suggested_groups',
              })}
            >
              <TextButton
                noWrap
                as="a"
                variant="secondary"
                data-hook="group-name"
                title={group.name as string}
              >
                {group.name}
              </TextButton>
            </UISref>
          </Box>
        }
        subtitle={<GroupInfo wired={wired} groupId={group.id as string} />}
        subtitleProps={{ noWrap: true }}
        titleProps={{
          className: cls(classes.name, { [classes.mobile]: isMobile }),
        }}
      />

      <ListItemAction>
        <GroupMembershipButton
          redirect
          upgrade={!isMobile}
          className={classes.action}
          size={isMobile ? ButtonSize.tiny : undefined}
          groupId={group.id as string}
          bi={groupsRequestJoinAGroup({
            group_id: group.id as string,
            origin: 'new_layout_groups_sidebar_suggested_groups',
          })}
        />
      </ListItemAction>
    </ListItem>
  );
}

SuggestedGroupListItem.displayName = 'SuggestedGroupListItem';
