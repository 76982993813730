import React from 'react';
import cls from 'classnames';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { UISref } from '@wix/tpa-router/react';
import { groupsSelectGroupFromList } from '@wix/bi-logger-groups/v2';

import type { IGroup } from 'store/groups';

import { Box } from 'wui/Box';
import { ListItem } from 'wui/ListItem';
import { ListItemText } from 'wui/ListItemText';
import { TextButton } from 'wui/TextButton';
import { ListItemIcon } from 'wui/ListItemIcon';
import type { TPAComponentProps } from 'wui/types';

import { GroupInfo } from 'common/components/GroupInfo';
import { RoleIcon } from 'common/components/RoleIcon';
import { GroupImage } from 'common/components/GroupImage';

import { EImageRatio } from 'settings/constants';

import classes from './GroupListItem.scss';

interface IGroupListItemProps extends TPAComponentProps {
  wired?: boolean;
  group: IGroup;
}

export function JoinedGroupListItem(props: IGroupListItemProps) {
  const { group, wired, className, ...rest } = props;

  const { isMobile } = useEnvironment();

  return (
    <ListItem
      {...rest}
      disablePadding
      className={cls(className, { [classes.wired]: wired })}
    >
      <ListItemIcon className={classes.image}>
        <UISref
          state="group"
          params={{ slug: group.slug }}
          bi={groupsSelectGroupFromList({
            groupId: group.id as string,
            origin: 'new_layout_groups_sidebar_my_groups',
          })}
        >
          <Box width={40} height={40} as="a">
            <GroupImage
              width={40}
              height={40}
              resize="cover"
              className={classes.image}
              image={group.coverImage?.image}
              aspectRatio={EImageRatio.Square}
            />
          </Box>
        </UISref>
      </ListItemIcon>

      <ListItemText
        title={
          <Box verticalAlign="middle">
            <UISref
              state="group"
              params={{ slug: group.slug }}
              bi={groupsSelectGroupFromList({
                groupId: group.id as string,
                origin: 'new_layout_groups_sidebar_my_groups',
              })}
            >
              <TextButton
                noWrap
                as="a"
                variant="secondary"
                data-hook="group-name"
                title={group.name as string}
                className={cls(classes.name, { [classes.mobile]: isMobile })}
              >
                {group.name}
              </TextButton>
            </UISref>

            <RoleIcon role={group.role} className={classes.roleIcon} />
          </Box>
        }
        subtitle={<GroupInfo misc wired={wired} groupId={group.id as string} />}
      />
    </ListItem>
  );
}

JoinedGroupListItem.displayName = 'JoinedGroupListItem';
